<template>
  <div class="bar-chart-wrapper">
    <svg :width="width"
         :height="height">
      <g :transform="`translate(${margin.left}, ${margin.top})`" class="chart-stack">
      </g>
      <g :transform="`translate(${margin.left}, ${margin.top})`" class="chart">
        <rect v-for="(d,i) in bars"
              :key="`hiddenrect-${i}`"
              :x="xScale(d.month)"
              :y="0"
              :width="xScale.bandwidth()"
              :height="chartHeight + hoverrectMehrYBottom"
              :data-month="d.month"
              class="hiddenrect"
              @mouseover="rectmouseover(d, i)"
              @mouseout="rectmouseout(d, i)"
        >
        </rect>
        <rect :x="0"
              :y="-hoverrectMehrYTop"
              :width="xScale.bandwidth()"
              :height="chartHeight + hoverrectMehrYTop + hoverrectMehrYBottom"
              fill="black"
              opacity="0.35"
              class="hoverrect">
        </rect>
      </g>
      <line :x1="0"
            :x2="width"
            :y1="margin.top + chartHeight + hoverrectMehrYBottom"
            :y2="margin.top + chartHeight + hoverrectMehrYBottom"
            :stroke="divisionLineColor"
            stroke-width="0.5">
      </line>
      <g :transform="`translate(${sliderMarginLeft}, ${margin.top + chartHeight + hoverrectMehrYBottom + 35})`" class="slider-wrapper">
        <slider v-if="sliderWidth && xDomain.length && domain.length"
                :sliderWidth="sliderWidth"
                :staticDomain="xDomain"
                :initialDomain="domain"
                :maximumSliderRange="maximumSliderRange"
                :sliderLabelFormat="sliderLabelFormat"
                @dragged="onSliderDrag"
        />
      </g>
      <Tooltip :obj="tooltipObj"
               :svgWidth="width"
               :svgHeight="height"
               :handleOverflowX="true"
               :handleOverflowY="false">
      </Tooltip>
    </svg>
  </div>
</template>

<script>
import patternify from '../d3-assets/patternify'
import Tooltip from '../d3-assets/Tooltip'
import Slider from '../d3-assets/Slider'

export default {
  name: 'bar-chart',
  props: [
    'label',
    'monthKeys',
    'graphData',
    'currency',
    'currencySymbol',
    'tooltipValueDecimals'
  ],
  data () {
    return {
      width: 0,
      height: 550,
      transitionTime: 750,
      sliderHeight: 180,
      hoverrectMehrYTop: 15,
      hoverrectMehrYBottom: 60,
      mehrHeight: 60,
      sliderMarginLeft: 50,
      axisCircleSize: 3.5,
      divisionLineColor: '#404A65',
      sliderBackColor: '#636e7f',
      circleColor: '#1971ff',
      darkColor: '#A5ADBA',
      barColors: [
        '#98ccad',
        '#EE00F7',
        '#00DEFB',
        '#F2006B',
        '#FFF0B2',
        '#FFBDAD',
        '#A5ADBA'
      ],
      sliderHandlerRadius: 12,
      opacityPercentage: 0.2,
      domain: [],
      zoomArea: [],
      tooltipObj: {
        visible: false
      },
      animated: false,
      margin: {
        top: 100,
        right: 15,
        bottom: 15,
        left: 110
      },
      chart: '',
      chartStack: '',
      debug: false
    }
  },
  computed: {
    slider () {
      return this.maximumSliderRange < this.xDomain.length
    },
    startLabel () {
      return this.graphData.transactions.first_month
    },
    endLabel () {
      return this.graphData.transactions.last_month
    },
    xDomain () {
      return Object.keys(this.graphData.months)
    },
    maximumSliderRange () {
      return Math.min(12, this.xDomain.length)
    },
    bars () {
      return this.domain.map(month => {
        return {
          color: this.colorScale(0),
          year: month.split('-')[0],
          monthText: this.monthKeys[month.split('-')[1]].full,
          month: month,
          value: this.graphData.months[month]
        }
      })
    },
    chartHeight () {
      return this.height - this.margin.top - this.margin.bottom - this.sliderHeight
    },
    chartWidth () {
      return this.width - this.margin.left - this.margin.right
    },
    sliderWidth () {
      return this.width - 2 * this.sliderMarginLeft
    },
    xScale () {
      return this.$d3.scaleBand()
        .domain(this.domain)
        .range([0, this.chartWidth])
        .paddingInner([0.05])
        .paddingOuter([0.1])
    },
    yScale () {
      const min = 0
      const max = this.$d3.max(this.bars, d => d.value)

      return this.$d3.scaleLinear()
        .domain([min, max])
        .range([this.chartHeight, 0])
    }
  },
  methods: {
    sliderLabelFormat (label) {
      var year = label.split('-')[0]
      var month = label.split('-')[1]

      return `${year} ${this.monthKeys[month].short}`
    },
    thousandsFormat (value) {
      // console.log(this.tooltipValueDecimals)
      if (this.tooltipValueDecimals !== undefined) {
        value = value.toFixed(this.tooltipValueDecimals)
      }

      const locale = this.$d3.formatLocale({
        decimal: ',',
        thousands: ' ',
        grouping: [3]
      })
      return locale.format(',')(value)
    },
    colorScale (i) {
      return this.barColors[i % this.barColors.length]
    },
    rectmouseover (d, i) {
      const html = `
        <div class="toolTip">
          <div class="tooltip-title">
            <strong><span id="tooltipyear">${d.year} ${d.monthText}</span></strong>
          </div>
          <div class="d-flex justify-content-between">
            <div class="mr-3">
              <span class="tooltip-amount-span" style="background-color:${this.colorScale(0)};">
              </span><span class="ml-1">${this.label}</span>
            </div>
            <div>
              <strong><span>${this.thousandsFormat(d.value)}</span> ${this.currency}</strong>
            </div>
          </div>
        </div>
      `

      const x = this.xScale(d.month) + this.xScale.bandwidth() / 2 + this.margin.left
      const y = this.margin.top / 2 + 15

      this.tooltipObj = {
        x: x,
        y: y,
        html: html,
        visible: true
      }

      // current tick selection
      const xTick = this.chart.select(`#xTick-${d.month}`)

      // make circle bigger and filled white
      const xTickCircle = xTick.select('circle')
      xTickCircle.attr('fill', '#fff')
        .attr('stroke', '#1971ff')
        .attr('r', 5)

      // display hover rect
      const hoverrect = this.$d3.select('.hoverrect')
      hoverrect.attr('x', this.xScale(d.month))
        .style('display', 'block')

      // make texts a little bit bigger
      xTick.selectAll('text').attr('fill', '#fff').attr('font-weight', 600)
    },
    rectmouseout (d, i) {
      // current tick selection
      const xTick = this.chart.select(`#xTick-${d.month}`)

      // make circle smaller
      const xTickCircle = xTick.select('circle')
      xTickCircle.attr('fill', '#1971ff')
        .attr('stroke', '#fff')
        .attr('r', 4)

      // hide hover rect
      const hoverrect = this.$d3.select('.hoverrect')
      hoverrect.style('display', 'none')

      // make texts smaller
      xTick.selectAll('text').attr('fill', this.darkColor).attr('font-weight', 400)
      this.tooltipObj.visible = false
    },
    calcOpacity (d, i) {
      const length = this.domain.length

      if (length <= 4) return 1

      const l = Math.round(length * this.opacityPercentage)
      const h = length - l

      const sc = this.$d3.scaleLinear().clamp(true)

      if (i <= l) {
        sc.domain([0, l]).range([0.25, 1])
        return sc(i)
      }

      if (i >= h) {
        sc.domain([h - 1, length - 1]).range([1, 0.25])
        return sc(i)
      }
      return 1
    },
    drawAxes () {
      const tickSize = this.chartHeight
      const xAxis = this.$d3.axisBottom(this.xScale).tickSize(-(tickSize))

      // append xAxis
      patternify({
        container: this.chart,
        tag: 'g',
        selector: 'xAxis'
      })
        .attr('class', 'xAxis axis')
        .attr('transform', `translate(${0}, ${this.chartHeight})`)
        .call(xAxis)

      const yAxis = this.$d3.axisLeft(this.yScale).ticks(5).tickFormat(d => this.thousandsFormat(d) + (this.currencySymbol ? ' ' + this.currencySymbol : ''))

      // append yAxis
      patternify({
        container: this.chart,
        tag: 'g',
        selector: 'yAxis'
      })
        .attr('class', 'yAxis axis')
        .call(yAxis)
    },
    adjustAxes () {
      const startEndTickSize = -(this.chartHeight + this.mehrHeight)

      const ticks = this.chart.select('g.xAxis')
        .selectAll('.tick')
        .attr('id', (d, i) => {
          return `xTick-${d}`
        })
        .attr('opacity', (d, i) => {
          return this.calcOpacity(d, i)
        })

      ticks.select('line')
        .attr('stroke', this.darkColor)
        .attr('stroke-width', 0.5)
        .attr('stroke-dasharray', '3 1.5')

      // reposition texts within the axis
      ticks.select('text')
        .attr('class', 'tick-year')
        .attr('dy', '4em')
        .attr('fill', this.darkColor)
        .attr('font-family', 'Nunito')
        .text((d, i) => {
          var year = d.split('-')[0]
          var lastYear = i > 0 ? this.xDomain[i - 1].split('-')[0] : null

          if (year) {
            if (i === 0) {
              return year
            } else if (lastYear !== year) {
              return year
            }
          }

          return ''
        })

      // append circles
      patternify({
        container: ticks,
        tag: 'circle',
        selector: 'tickCircle'
      })
        .attr('r', this.axisCircleSize)
        .attr('fill', this.circleColor)
        .attr('stroke', '#fff')
        .attr('stroke-width', 1)

      // append months
      patternify({
        container: ticks,
        tag: 'text',
        selector: 'secondaryAxisText',
        data: d => [d]
      })
        .attr('class', 'secondaryAxisText')
        .attr('dy', '2.5em')
        .attr('font-family', 'Roboto')
        .text((d) => {
          const month = d.split('-')[1]

          if (month) {
            return this.monthKeys[month].short
          }

          return ''
        })

      // adjust xAxis
      const yTicks = this.chart.select('g.yAxis')
        .selectAll('.tick')

      // reposition lines
      yTicks.select('line')
        .attr('x1', (d, i) => {
          if (i) {
            return -7
          }
          return 0
        })
        .attr('x2', (d, i) => {
          if (i) {
            return 4
          }
          return 0
        })
        .attr('stroke-width', 2)

      yTicks.select('text')
        .attr('x', -24)
        .attr('font-family', 'Nunito')

      // append circles
      patternify({
        container: yTicks,
        tag: 'circle',
        selector: 'tickCircle'
      })
        .attr('r', this.axisCircleSize)
        .attr('cx', -10)
        .attr('fill', '#6D7290')
        .attr('stroke', '#ffffff')
        .attr('stroke-width', 1)

      if (this.startLabel === this.endLabel) return

      if (this.startLabel >= this.domain[0]) {
        this.chart.selectAll('circle.startYearCircle').remove()

        // start year tick selection
        const startYearTick = this.chart.select(`#xTick-${this.startLabel}`)

        startYearTick
          .select('line')
          .attr('y2', startEndTickSize)
          .attr('stroke', '#fff')
          .style('opacity', 1)

        // append circle for the startYear tick
        patternify({
          container: startYearTick,
          tag: 'circle',
          selector: 'startYearCircle'
        })
          .attr('r', this.axisCircleSize)
          .attr('fill', this.circleColor)
          .attr('stroke', '#fff')
          .attr('stroke-width', 1.5)
          .attr('transform', `translate(0, ${startEndTickSize})`)

        // start year text group
        const startYearGroup = patternify({
          container: this.chart,
          tag: 'g',
          selector: 'startYear'
        })
          .attr('transform', `translate(${this.xScale(this.startLabel) + this.xScale.bandwidth() / 2 + 10}, ${this.mehrHeight - this.margin.top - 15})`)

        patternify({
          container: startYearGroup,
          tag: 'text',
          selector: 'startYearTxt'
        })
          .text(this.$t('common.start_year'))
          .attr('fill', this.darkColor)
          .attr('font-size', '0.8rem')
          .attr('font-family', 'Nunito')

        patternify({
          container: startYearGroup,
          tag: 'text',
          selector: 'startYearY'
        })
          .text(this.startLabel.split('-')[0])
          .attr('fill', '#fff')
          .attr('font-size', '13px')
          .attr('dy', 16)
          .attr('font-family', 'Nunito')
      } else {
        this.chart.selectAll('g.startYear').remove()
      }

      if (this.endLabel <= this.domain[this.domain.length - 1]) {
        this.chart.selectAll('circle.endYearCircle')
          .remove()
        // end year tick selection
        const endYeartTick = this.chart.select(`#xTick-${this.endLabel}`)
        endYeartTick
          .select('line')
          .attr('y2', startEndTickSize)
          .attr('stroke', '#fff')
          .attr('opacity', 0.7)

        // add circle to the end year tick
        patternify({
          container: endYeartTick,
          tag: 'circle',
          selector: 'endYearCircle'
        })
          .attr('r', this.axisCircleSize)
          .attr('fill', '#1971ff')
          .attr('stroke', '#fff')
          .attr('stroke-width', 1.5)
          .attr('transform', `translate(0, ${startEndTickSize})`)

        let translateX = this.xScale(this.endLabel) + this.xScale.bandwidth() / 2 + 10
        const translateY = this.mehrHeight - this.margin.top - 15

        if (this.endLabel >= this.domain[this.domain.length - 2]) {
          translateX -= 70
        }

        // end year text group
        const endYearGroup = patternify({
          container: this.chart,
          tag: 'g',
          selector: 'endYear'
        })
          .attr('transform', `translate(${translateX}, ${translateY})`)

        patternify({
          container: endYearGroup,
          tag: 'text',
          selector: 'endYearTxt'
        })
          .text(this.$t('common.end_year'))
          .attr('fill', this.darkColor)
          .attr('font-size', '0.8rem')
          .attr('font-family', 'Nunito')

        patternify({
          container: endYearGroup,
          tag: 'text',
          selector: 'endYearY'
        })
          .text(this.endLabel.split('-')[0])
          .attr('fill', '#fff')
          .attr('font-size', '13px')
          .attr('dy', 16)
          .attr('font-family', 'Nunito')
      } else {
        this.chart.selectAll('g.endYear').remove()
      }
    },
    drawRects () {
      patternify({
        tag: 'rect',
        selector: 'bar-rect',
        container: this.chartStack,
        data: this.bars
      })
        .attr('data-month', d => d.month)
        .attr('height', 0)
        .attr('width', this.xScale.bandwidth())
        .attr('x', d => this.xScale(d.month))
        .attr('y', this.chartHeight)
        .attr('fill', this.colorScale(0))
        .attr('opacity', (d, i) => this.calcOpacity(d, i))
        .transition()
        .duration(this.animated ? 0 : this.transitionTime)
        .attr('height', d => this.chartHeight - this.yScale(d.value))
        .attr('y', d => this.yScale(d.value))
        .on('end', () => {
          this.animated = true
        })
    },
    setWidth () {
      this.width = this.$el.offsetWidth
    },
    setDomain () {
      this.domain = []

      for (let i = this.xDomain.length - this.maximumSliderRange; i < this.xDomain.length; i++) {
        var d = this.xDomain[i]
        this.domain.push(d)
      }
    },
    onSliderDrag (domain) {
      this.domain = domain

      this.drawAxes()
      this.drawRects()
      this.adjustAxes()
    },
    onResize () {
      this.setWidth()
      this.drawAxes()
      this.drawRects()
      this.adjustAxes()
    }
  },
  watch: {
    xDomain () {
      this.setDomain()
      this.drawAxes()
      this.adjustAxes()
    },
    bars () {
      this.onResize()
    }
  },
  mounted () {
    this.chart = this.$d3.select(this.$el).select('svg .chart')
    this.chartStack = this.$d3.select(this.$el).select('svg .chart-stack')
    this.setDomain()
    this.onResize()
    this.$d3.select(window).on('resize', this.onResize)
  },
  components: {
    Tooltip,
    Slider
  }
}
</script>

<style lang="scss">
@import 'style.scss'
</style>
